import { request } from "@/utils/request"

export function UserLogin(data: any) {
  return request({
    // url: "/api/user/login",
    url: "/api/user/email-login",
    method: "post",
    data: { data: data },
  });
}
/**
 *获取用户权限
 * */
export function getUserInfoApi() {
  return request({
    url: "/api/user/permission",
    method: "POST",
  });
}
/**
 * 用户登出
 * **/
export function logout() {
  return request({
    url: "/api/user/logout",
    method: "post",
  });
}

// 发送邮箱验证码
export function getEmailCode (data: any) {
  return request({
    url: '/api/user/send-email-code',
    method: 'post',
    data: {data}
  });
}

// 忘记密码
export function updatePassword (data: any) {
  return request({
    url: '/api/user/forgot-password',
    method: 'post',
    data: {data}
  });
} 

// 邮箱注册
export function emailRegistration (data: any) {
  return request({
    url: '/api/user/email-register',
    method: 'post',
    data: {data}
  });
} 

// 分销商入驻
export function entryDistributor (data: any) {
  return request({
    url: '/api/user/save',
    method: 'post',
    data: {data}
  });
} 

// 获取区号
export function getAreaCode () {
  return request({
    url: '/api/user/get-mobile-prefix',
    method: 'post',
  });
} 

// 获取国家
export function getCountry () {
  return request({
    url: '/api/address/country',
    method: 'post',
  });
} 

// 审批状态
export function getMemberInfo () {
  return request({
    url: '/api/user/member-info',
    method: 'post',
  });
} 

// 修改密码
export function editPassword (data: any) {
  return request({
    url: '/api/user/edit-password',
    method: 'post',
    data: {data}
  });
} 
