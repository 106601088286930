import i18n from "@/lang"
const { t } = i18n.global;

//不同语言返回对应文案
const getLabelName = (name: string)=>{
    return localStorage.getItem('lang') ==='en'? 'en_'+name : name;
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
function toThousandFilter(num: number) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}
/**
 * add ￥
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addMoney(val: number) {
    let tempVal = (val||0).toString();
    if(isNaN(Number(val))){ return '$0';}
    return  (tempVal.substring(0,1) =='-' ? '$-' :'$')+toThousandFilter(Math.abs(val));
}
/**
 * add ￥
 * 金额减少 多少钱
 * 
 */
function addReduceMoney(val: number) {
    let tempVal = (val||0).toString();
    if(isNaN(Number(val))){ return '$0';}
    return  (tempVal.substring(0,1) =='-' ? '-$' :'$')+toThousandFilter(Math.abs(val));
}
/**
 * string 默认 --
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addDefaultString(val: any) {
    return  val ? val:'--';
}
//修改页面标题
const  getPageTitle = (pageTitle: any) => {
    if (pageTitle) {
      return `${t(pageTitle)} - 上船吧`
    }
    return "上船吧"
}

export function timeToDate(time) {
    if (time >= 0) {
        const d = Math.floor(time / 60 / 60 / 24);
        const h = Math.floor((time / 60 / 60) % 24);
        const m = Math.floor((time / 60) % 60);
        const s = Math.floor(time % 60);
        return (
        d + "天" + toDouble(h) + "时" + toDouble(m) + "分" + toDouble(s) + "秒"
        );
    }
}   
function toDouble(n) {
    return n < 10 ? "0" + n : n;
}


export function useFunction(){
    return {
        getLabelName,
        addMoney,
        addReduceMoney,
        addDefaultString,
        toThousandFilter,
        getPageTitle,
        timeToDate
    }
}