<template>
  <svg class="icon-svg" aria-hidden="true" v-bind="$attrs">
    <use :xlink:href="iconName" ></use>
  </svg>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  prefix: {
    type: String,
    default: "icon"
  },
  name: {
    type: String,
    required: true
  }
})
const iconName = computed(() => `#${props.prefix}-${props.name}`)
</script>

<style lang="scss" scoped>
.icon-svg {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
  pointer-events: none;
}
</style>