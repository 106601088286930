import { ref } from "vue"
import store from "@/store"
import { defineStore } from "pinia"
import { usePermissionStore } from "./permission"
import { getToken, removeToken, setToken } from "@/utils/cache/cookies"
import router, { resetRouter } from "@/router"
import {  UserLogin, getUserInfoApi } from "@/api/user"

export const useUserStore = defineStore("user", () => {
  const token = ref(getToken() || "")
  const roles = ref([])
  const userInfo = ref({
    "department":"",
    "email":"",
    "id": 1,
    "login_name": "admin",
    "mobile": "",
    "org_id": 0,
    "update_time": "",
    "username": "",
  });

  /** 设置角色数组 */
  const setRoles = (value: never[]) => {
    roles.value = value
  }
  /** 登录 */
  const login = (loginData: { username: any; password: any }) => {
    return new Promise((resolve, reject) => {
      UserLogin({
        email: loginData.username,
        password: loginData.password
      })
        .then((res: { data: { token: string, member: any }}) => {
          if(res.code === 10000 || res.code === 200) {    
            setToken(res.data.token);
            token.value = res.data.token;
            userInfo.value = res.data.member
            let active = "1"
            if(res.data.member.approval_status === 0)  {
              active = '2'
            }else if(res.data.member.approval_status >= 1) {
              active = '3'
            }
            const itemsToSet = {
              active: active,
              approvalStatus: res.data.member.approval_status || "",
              approvalRemark: res.data.member.approval_remark || "",
              firstName: res.data.member.first_name || "",
              middleName: res.data.member.middle_name || "",
              lastName: res.data.member.last_name || "",
              email: res.data.member.email || "",
              name: res.data.member.name || "",
              brandName: res.data.member.brand_name || ""
            };
            
            Object.keys(itemsToSet).forEach(key => {
                localStorage.setItem(key, itemsToSet[key]);
            });
            resolve(res.data.member.approval_status)
          }
        })
        .catch((error: any) => {
          reject(error)
        })
    });
  }
  /** 获取用户详情 */
  const getInfo = () => {
    return new Promise((resolve, reject) => {
      
      getUserInfoApi()
        .then((res: any) => {
          roles.value = res.data.roles
          userInfo.value = res.data
          resolve(res)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }
  /** 切换角色 */
  const changeRoles = async (role: string) => {
    const newToken = "token-" + role
    token.value = newToken
    setToken(newToken)
    await getInfo()
    const permissionStore = usePermissionStore()
    permissionStore.setRoutes(roles.value)
    resetRouter()
    permissionStore.dynamicRoutes.forEach((item: any) => {
      router.addRoute(item)
    })
  }
  /** 登出 */
  const logout = () => {
    removeToken()
    token.value = ""
    roles.value = []
    const keysToRemove = ['approvalStatus', 'approvalRemark', 'active', 'firstName', 'middleName', 'lastName', 'name', 'email', 'brandName', 'isCommission'];
    keysToRemove.forEach(key => {
        localStorage.removeItem(key);
    });
    // resetRouter()
  }
  /** 重置 Token */
  const resetToken = () => {
    removeToken()
    token.value = ""
    roles.value = []
  }
  return { token, roles, userInfo, setRoles, login, logout, resetToken }
})

