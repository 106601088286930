<template>
  <el-config-provider :button="button" :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>
<script lang="ts">
import { defineComponent, reactive, computed } from 'vue'
import "@/assets/styles/variables.scss"

import En from 'element-plus/es/locale/lang/en';
import ZhCn from 'element-plus/es/locale/lang/zh-cn';
export default defineComponent({
  setup() {
    const button = reactive({
      autoInsertSpace:true
    });
    const locale = computed(()=>{
      return localStorage.getItem('lang') ==='en'? En :ZhCn;
    });
    return {
      button,
      locale
    }
  }
})
</script>